const {fr} = require("date-fns/locale");
module.exports = Object.assign({dateFnsLocale: fr}, {
  "chapo_length_number": 559,
  "date_format": {
    "en": "MMMM d yyyy"
  },
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "notifications_service_endpoint": "https://apim.keolis.com/SIK/000/notification",
  "search_token": "5344199e9f486a07dcff12ff83b8e9",
  "v3_share_label": {
    "en": "I share"
  },
  "hour_format": {
    "en": "HH:mm"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/SIK/000/file/import HTTP/1.1",
  "piano_site_id": "639189",
  "site_name": "Keolis MHI",
  "v3_social_networks": [
    "NMVuzrX7Rju7X-6XxxBb_Q",
    "HdkYQhjiRTiQZ_EUqsa9Sw",
    "UjmbM7TISyWkKI0ohh_aXg",
    "WHuXStP_SJuGOcof92-vyw"
  ],
  "facebook_link": "https://www.facebook.com/sharer/sharer.php?u=",
  "google_tag_manager_id": "GTM-MR9X2CJM",
  "meta_description": {
    "en": "Keolis MHI"
  },
  "notifications_service_token": "2d5392f4a9404b2690515b92a3eadc93",
  "google_analytics_key": "UA-15538359-5",
  "subsidiary_logo": {
    "en": {
      "path": "/80791/1665408468-km-logo_eng.jpg",
      "format": "jpg",
      "size": 68926,
      "alt": "Keolis MHI - back to homepage",
      "title": "Keolis MHI",
      "width": 1953,
      "height": 249
    }
  },
  "twitter_link": "https://twitter.com/intent/tweet?url=",
  "linkedin_link": "https://www.linkedin.com/sharing/share-offsite/?url=",
  "subsidiary_reduced_logo": {
    "en": {
      "path": "/80791/1724158932-logo_k_bleu-size5.svg",
      "format": "svg",
      "size": 754,
      "alt": "Logo Keolis",
      "title": "Keolis",
      "width": 256,
      "height": 256
    }
  },
  "home_page_title": {
    "en": "Home"
  },
  "mailto_addresse": "mailto:someone@example.com",
  "share_buttons_title": {
    "en": "share"
  },
  "transport_mode_title": {
    "en": ""
  },
  "facebook_label": {
    "en": "facebook label"
  },
  "token_google_site_verification": "",
  "twitter_label": {
    "en": "twitter label"
  },
  "linkedin_label": {
    "en": "linkedIn label"
  },
  "mailto_label": {
    "en": "mailTo label"
  },
  "download_label": {
    "en": "Télécharger"
  },
  "updated_at": "2024-08-20T15:02:45.736+02:00",
  "created_at": "2021-04-20T17:40:04.593+02:00",
  "locale": "fr",
  "locales": [
    "en"
  ],
  "siteUrl": "https://www.keolis-mhi.com/"
})